import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/indigo';

import App from './components/App';

import store from './redux/store';

import './index.scss';

const Config = () => (
  <Provider store={store}>
    <MuiThemeProvider theme={createMuiTheme({
      palette: {
        primary: blue,
        secondary: blue,
      }
    })}>
      <App />
    </MuiThemeProvider>
  </Provider>
);

ReactDOM.render(<Config />, document.getElementById('root'));
