import React, { Component } from 'react';
import * as firebase from 'firebase';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Switch from '@material-ui/core/Switch';

import Layout from '../../../layout/Admin';
import Title from '../components/Title';

import { getRestaurants, updateRestaurants } from '../../../../functions/api';
import history from '../../../../history';
import { paths } from '../../../../config';
import { displayModal } from '../../../../redux/actions/app';

import s from './style.module.scss';

class RestaurantList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      restaurants : [],
    };

    this.redirectToRestaurant = this.redirectToRestaurant.bind(this);
    this.removeRestaurant = this.removeRestaurant.bind(this);
    this.changeRestaurantState = this.changeRestaurantState.bind(this);
    this.changeAllRestaurantsState = this.changeAllRestaurantsState.bind(this);
  }

  async componentWillMount() {
    const restaurants = await getRestaurants();

    this.setState({ restaurants });
  }

  redirectToRestaurant(url) {
    return (event) => {
      const target = event.target.nodeName.toLowerCase();
      const isName = event.target.getAttribute('data-id');

      if(target === 'li' || isName) history.push(paths.adminRestaurantDetail.replace(':restaurantId', url));
    }
  }

  removeRestaurant(name) {
    const { displayModal } = this.props;

    return (event) => {
      displayModal({
        title: 'Upozornění',
        description: 'Opravdu chcete odstanit tuto restauraci ?',
        onContinue: async () => {
          const { restaurants } = this.state;
          const updatedRestaurants = restaurants.filter(restaurant => restaurant.name !== name);

          try {
            await firebase
              .database()
              .ref('/restaurants')
              .set(updatedRestaurants);

            this.setState({ restaurants: updatedRestaurants });
          } catch (exception) {
            console.error(exception);
          }
        },
      });
    }
  }

  changeRestaurantState(name) {
    return async (event) => {
      const { restaurants } = this.state;

      const initialState = restaurants;

      const updatedRestaurants = restaurants.map(r => {
        const { active, ...restaurant } = r;

        if (restaurant.name === name) {
          return {
            ...restaurant,
            active: !active,
          }
        } else {
          return r;
        }
      });

      this.setState({ restaurants: updatedRestaurants });

      try {
        await updateRestaurants(updatedRestaurants);
      } catch (exception) {
        this.setState({ restaurants: initialState });
      }


    }
  }

  async changeAllRestaurantsState(state) {
    const { restaurants } = this.state;
    const initialState = restaurants;

    const updatedRestaurants = restaurants.map(restaurant => {
      return {
        ...restaurant,
        active: state,
      }
    });

    this.setState({ restaurants: updatedRestaurants });

    try {
      await updateRestaurants(updatedRestaurants);
    } catch (exception) {
      this.setState({ restaurants: initialState });
    }
  }

  moveRestaurant(restaurantIndex) {
    return async () => {
      const { restaurants } = this.state;
      const initialState = restaurants;

      if (restaurantIndex === 0 || restaurantIndex === restaurants.length) {
        return;
      }

      const restaurantOne = restaurants[restaurantIndex - 1];
      const restaurantTwo = restaurants[restaurantIndex];

      const updatedRestaurants = restaurants
        .map((restaurant, index) => {
          return  (index === restaurantIndex - 1) ? restaurantTwo :
                  (index === restaurantIndex) ? restaurantOne : restaurant;
        });

      this.setState({
        restaurants: updatedRestaurants,
      });

      try {
        await updateRestaurants(updatedRestaurants);
      } catch (exception) {
        this.setState({ restaurants: initialState})
      }
    }
  }

  render() {
    const {
      restaurants,
    } = this.state;

    return (
      <Layout>
        <div className={s.container}>
          <Title>Restaurace</Title>
          <div className={s.changeStateContainer}>
            <div>
              <Link to={paths.adminNewRestaurant} className={s.addRestaurantButton}>Přidat restauraci</Link>
            </div>
            <div className={s.changeStateButtonsContainer}>
              <span onClick={() => this.changeAllRestaurantsState(false)} className={s.changeStateButton}>Deaktivovat vše</span>
              <span>/</span>
              <span onClick={() => this.changeAllRestaurantsState(true)} className={s.changeStateButton}>Aktivovat vše</span>
            </div>
          </div>
          {restaurants.length ?
            <ul className={s.restaurantList}>
              {restaurants.map((restaurant, index) => (
                <li
                  key={restaurant.url}
                  className={s.restaurantItem}
                  onClick={this.redirectToRestaurant(restaurant.url)}
                >
                  <div>
                    <Icon
                      className={s.restaurantOrderButton}
                      onClick={this.moveRestaurant(index + 1)}
                    >keyboard_arrow_down</Icon>
                    <Icon
                      className={s.restaurantOrderButton}
                      onClick={this.moveRestaurant(index)}
                    >keyboard_arrow_up</Icon>
                  </div>
                  <span
                    data-id="restaurant-name"
                    className={s.restaurantName}
                  >{restaurant.name}</span>
                  <div className={s.restaurantControls}>
                    <Switch
                      checked={restaurant.active}
                      onChange={this.changeRestaurantState(restaurant.name)}
                    />
                    <IconButton onClick={this.removeRestaurant(restaurant.name)}><Icon>delete_forever</Icon></IconButton>
                  </div>
                </li>
              ))}
            </ul>
            :
            <div className={s.loaderContainer}>
              <CircularProgress size={200} style={{ color: '#e00000' }}/>
            </div>
          }
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    displayModal(modal) {
      dispatch(displayModal(modal));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantList);
