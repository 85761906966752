import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as firebase from 'firebase';
import shortId from 'shortid';
import { remove as removeDiacritics } from 'diacritics';
import update from 'immutability-helper';
import {
  arrayMove,
} from 'react-sortable-hoc';

import Layout from '../../../layout/Admin';
import Button from '../../../common/Button';

import CategoryList from './components/CategoryList';

import { getRestaurants } from '../../../../functions/api';
import { getDayName } from '../../../../functions/utils';
import history from '../../../../history';
import { paths } from '../../../../config';

import s from './style.module.scss';

class RestaurantDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialData: null,
      restaurant: null,

      activeCategory: null,
      categoryInput: '',
      mealInput: {
        name: '',
        description: '',
        price: '',
        key: shortId.generate(),
      }
    };

    this.changeRestaurantInfo = this.changeRestaurantInfo.bind(this);
    this.changeOpeningHour = this.changeOpeningHour.bind(this);
    this.changeCategoryName = this.changeCategoryName.bind(this);
    this.expandCategory = this.expandCategory.bind(this);
    this.changeMealProperty = this.changeMealProperty.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
    this.removeObjectProperty = this.removeObjectProperty.bind(this);
    this.changeNewCategoryValue = this.changeNewCategoryValue.bind(this);
    this.addCategory = this.addCategory.bind(this);
    this.onMealListSortEnd = this.onMealListSortEnd.bind(this);
    this.removeMeal = this.removeMeal.bind(this);
    this.removeCategory = this.removeCategory.bind(this);
    this.changeNewMealValue = this.changeNewMealValue.bind(this);
    this.addMeal = this.addMeal.bind(this);
    this.updateMealProperty = this.updateMealProperty.bind(this);
    this.updateCategory = this.updateCategory.bind(this);
  }

  async componentWillMount() {
    const { match } = this.props;
    const restaurants = await getRestaurants();

    const currentRestaurant = restaurants.find(restaurant => restaurant.url === match.params.restaurantId);

    if (!currentRestaurant) {
      const r = {
        name: '',
        email: '',
        openingHours: [
          { from: '', to: '' },
          { from: '', to: '' },
          { from: '', to: '' },
          { from: '', to: '' },
          { from: '', to: '' },
          { from: '', to: '' },
          { from: '', to: '' },
        ],
        categories: [],
      };

      this.setState({
        restaurant: r,
        initialData: r,
      })
    } else {
      const restaurantWithKeys = {
        ...currentRestaurant,
        categories: currentRestaurant.categories.map(category => {

          return {
            ...category,
            key: shortId.generate(),
            meals: category.meals.map(meal => {
              return {
                ...meal,
                key: shortId.generate(),
              };
            })
          }
        }),
      };

      this.setState({
        restaurant: restaurantWithKeys,
        initialData: currentRestaurant,
      });
    }
  }

  changeRestaurantInfo(name) {
    return (event) => {
      const { value } = event.target;

      this.setState(state => {
        return {
          restaurant: {
            ...state.restaurant,
            [name]: value,
          },
        }
      });
    }
  }

  changeOpeningHour(type, index) {
    return (event) => {
      const { value } = event.target;

      this.setState(state => {
        const updatedOpeningHours = state.restaurant.openingHours.map((o, i) => {
          return i === index ? { ...o, [type]: value } : o;
        });

        return {
          restaurant: {
            ...state.restaurant,
            openingHours: updatedOpeningHours,
          }
        }
      });
    };
  }

  changeCategoryName(index) {
    return (event) => {
      const { value } = event.target;

      this.setState(state => {
        const updatedCategories = state.restaurant.categories.map((c, i) => {
          return i === index ? { ...c, name: value } : c;
        });

        return {
          restaurant: {
            ...state.restaurant,
            categories: updatedCategories,
          }
        }
      })
    }
  }

  updateCategory (categoryIndex, item, value) {
    this.setState(s => update(s, {
      restaurant: {
        categories: {
          [categoryIndex]: {
            [item]: {
              $set: value,
            }
          }
        }
      }
    }));
  }

  expandCategory(index) {
    this.setState(state => {
      const activeCategory = state.activeCategory === index ? null : index;

      return { activeCategory };
    })
  }

  changeMealProperty(property, categoryIndex, mealIndex) {
    return (event) => {
      const { value } = event.target;

      this.setState(state => {
        const updatedCategories = state.restaurant.categories
          .map((c, index) => {
            if (index === categoryIndex) {
              return {
                ...c,
                meals: c.meals.map((meal, idx) => idx === mealIndex ? { ...meal, [property]: value } : meal),
              }
            } else return c;
          });

        return {
          restaurant: {
            ...state.restaurant,
            categories: updatedCategories,
          }
        };
      });
    }
  }

  removeObjectProperty(key, object) {
    const { [key]: omit, ...o } = object;

    return o;
  }

  async saveChanges(event) {
    event.preventDefault();

    const { match } = this.props;
    const restaurants = await getRestaurants();
    const currentRestaurant = restaurants.find(restaurant => restaurant.url === match.params.restaurantId);

    if (currentRestaurant) {
      const {
        initialData,
        restaurant,
      } = this.state;

      const save = restaurants.map(r => {
        if (r.url === initialData.url) {
          const updatedCategories = restaurant.categories.map(c => {
            const category = this.removeObjectProperty('key', c);

            return {
              ...category,
              meals: category.meals.map(m => {
                const updatedMeal = this.removeObjectProperty('key', m);

                return {
                  ...updatedMeal,
                  price: parseInt(updatedMeal.price, 10),
                }
              }),
            }
          });

          const { isOpen, ...updatedRestaurant } = restaurant;

          return {
            ...updatedRestaurant,
            categories: updatedCategories,
          }
        } else {
          return r;
        }
      });

      try {
        await firebase
          .database()
          .ref('/restaurants')
          .set(save);

        history.push(paths.adminRestaurant);
      } catch (exception) {
        console.error(exception);
      }
    } else {
      const { restaurant } = this.state;

      const url = removeDiacritics(restaurant.name)
        .replace(/\s/g, '-')
        .toLowerCase();

      try {
        await firebase
          .database()
          .ref('/restaurants')
          .set(restaurants.concat([
            {
              ...restaurant,
              url,
              active: true,
            }
          ]));

        history.push(paths.adminRestaurant);
      } catch (exception) {
        console.error(exception);
      }
    }

    return false;
  }

  updateMealProperty (property, value, categoryIndex, mealIndex) {
    this.setState(update(this.state, {
      restaurant: {
        categories: {
          [categoryIndex]: {
            meals: {
              [mealIndex]: {
                [property]: {
                  $set: value,
                }
              }
            }
          }
        }
      }
    })); 
  }

  changeNewCategoryValue(e) {
    const { value } = e.target;

    this.setState({
      categoryInput: value,
    });
  }

  addCategory() {
    this.setState(state => {
      const restaurant = state.restaurant;
      const updatedCategories = restaurant.categories.concat([{
        meals: [],
        name: state.categoryInput,
        key: shortId.generate(),
      }]);

      return {
        restaurant: {
          ...state.restaurant,
          categories: updatedCategories,
        },
        categoryInput: '',
      }
    });
  }

  onMealListSortEnd(category) {
    return ({ oldIndex, newIndex }) => {
      this.setState(state => {
        return {
          restaurant: {
            ...state.restaurant,
            categories: state.restaurant.categories.map((c, i) => {
              if (i === category.index) {
                return {
                  ...c,
                  meals: arrayMove(c.meals, oldIndex, newIndex),
                }
              } else {
                return c;
              }
            })
          }
        }
      });

    };
  }

  removeMeal(categoryIndex, mealIndex) {
    this.setState(state => {
      const { restaurant } = state;
      const updatedCategories = restaurant.categories.map((category, index) => {
        if (categoryIndex === index) {
          const updatedMeals = category.meals.filter((meal, i) => i !== mealIndex);

          return {
            ...category,
            meals: updatedMeals,
          }
        } else {
          return category;
        }
      });

      return {
        restaurant: {
          ...state.restaurant,
          categories: updatedCategories,
        }
      }
    })
  }

  removeCategory(categoryIndex) {
    this.setState(state => {
      const { restaurant } = state;
      const updatedCategories = restaurant.categories.filter((category, index) => index !== categoryIndex);

      return {
        restaurant: {
          ...state.restaurant,
          categories: updatedCategories,
        }
      }
    })
  }

  changeNewMealValue(name) {
    return (e) => {
      const { value } = e.target;

      this.setState(state => {
        return {
          mealInput: {
            ...state.mealInput,
            [name]: value,
          }
        }
      })
    }
  }

  addMeal(categoryIndex) {
    return () => {
      this.setState(state => {
        const updatedCategories = state.restaurant.categories.map((category, index) => {
          if (index === categoryIndex) {
            return {
              ...category,
              meals: category.meals.concat([state.mealInput]),
            }
          } else {
            return category;
          }
        });

        return {
          restaurant: {
            ...state.restaurant,
            categories: updatedCategories,
          },
          mealInput: {
            name: '',
            description: '',
            price: '',
            key: shortId.generate(),
          }
        }
      })
    }
  }

  render() {
    const {
      restaurant,
      activeCategory,
    } = this.state;

    return (
      <Layout>
        {restaurant ?
          <form className={s.container} onSubmit={this.saveChanges}>
            <h1 className={s.restaurantTitle}>{restaurant.name || 'Nová restaurace'}</h1>
            <div className={s.emailContainer}>
              <span className={s.restaurantInputLabel}>Název restaurace: </span>
              <input
                value={restaurant.name}
                onChange={this.changeRestaurantInfo('name')}
                className={s.restaurantEmail}
                placeholder="Název restaurace"
              />
            </div>
            <div className={s.emailContainer}>
              <span className={s.restaurantInputLabel}>Emailová adresa: </span>
              <input
                type="email"
                value={restaurant.email}
                onChange={this.changeRestaurantInfo('email')}
                className={s.restaurantEmail}
                placeholder="Email restaurace"
              />
            </div>
            <div className={s.emailContainer}>
              <span className={s.restaurantInputLabel}>Externí odkaz: </span>
              <input
                value={restaurant.externalLink}
                onChange={this.changeRestaurantInfo('externalLink')}
                className={s.restaurantExternalLink}
                placeholder="Externí odkaz"
              />
            </div>
            <div className={s.openingHoursContainer}>
              <p className={s.blockTitle}>Otevírací doba</p>
              {restaurant.openingHours.map((opening, index) => (
                <div className={s.openingHoursItem} key={index}>
                  <span className={s.dayName}>{getDayName(index)}</span>
                  <input
                    type="text"
                    value={opening.from}
                    className={s.openingHoursInput}
                    onChange={this.changeOpeningHour('from', index)}
                    placeholder="10:00"
                  />
                  <span>-</span>
                  <input
                    type="text"
                    value={opening.to}
                    className={s.openingHoursInput}
                    onChange={this.changeOpeningHour('to', index)}
                    placeholder="10:00"
                  />
                </div>
              ))}
            </div>
            <div>
              <p className={s.blockTitle}>Kategorie</p>
              <CategoryList
                useDragHandle={true}
                items={this.state.restaurant.categories.map((c, i) => ({ ...c, index: i }))}
                onSortEnd={({ oldIndex, newIndex }) => {
                  this.setState(state => {
                    return {
                      restaurant: {
                        ...state.restaurant,
                        categories: arrayMove(state.restaurant.categories, oldIndex, newIndex),
                      }
                    }
                  })
                }}

                updateCategory={this.updateCategory}
                changeMealProperty={this.changeMealProperty}
                categoryInput={this.state.categoryInput}
                activeCategory={activeCategory}
                expandCategory={this.expandCategory}
                changeCategoryName={this.changeCategoryName}
                addCategory={this.addCategory}
                changeNewCategoryValue={this.changeNewCategoryValue}
                onMealListSortEnd={this.onMealListSortEnd}
                removeMeal={this.removeMeal}
                removeCategory={this.removeCategory}
                changeNewMealValue={this.changeNewMealValue}
                mealInput={this.state.mealInput}
                addMeal={this.addMeal}
                updateMealProperty={this.updateMealProperty}
              />
              <div className={s.addCategoryContainer}>
                <input
                  type="text"
                  placeholder="Název kategorie"
                  className={s.addCategoryInput}
                  onChange={this.changeNewCategoryValue}
                  value={this.state.categoryInput}
                />
                <span
                  className={s.addCategoryButton}
                  onClick={this.addCategory}
                >Přidat kategorii</span>
              </div>
            </div>
            <Button type="submit" classes={{ root: s.saveButton }}>Uložit změny</Button>
          </form>
          :
          <div className={s.loaderContainer}>
            <CircularProgress size={200} style={{ color: '#e00000' }}/>
          </div>
        }

      </Layout>
    );
  }
}

export default RestaurantDetail;
