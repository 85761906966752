import { SET_RESTAURANTS } from '../constants';

const initialState = [];

/**
 * Restaurant reducer
 * @param { object } state
 * @param { object } action
 * @return { object }
 */
export default function(state = initialState, action) {
  switch (action.type) {
    case SET_RESTAURANTS:
      return action.restaurants;

    default:
      return state;
  }
}
