import React, { Component } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import Layout from '../../../layout/Admin';
import Title from '../components/Title';
import OrderItem from './components/OrderItem';

import { getLastOrders } from '../../../../functions/api';

import s from './style.module.css';

class OrdersList extends Component {
  state = {
    orders: null,
  };
  
  async componentWillMount() {
    this.setState({
      orders: await getLastOrders(50),
    });
  }

  render () {
    const { orders } = this.state;

    return (
      <Layout>
        <Title>Seznam objednávek</Title>
        {orders === null ? 
          <div className={s.loadingContainer}>
            <CircularProgress size={200} style={{ color: '#e00000' }}/>
          </div>
          :
          <div>
            {orders.map((order, index) => <OrderItem key={index} order={order} />)}
          </div>
        }
      </Layout>
    );
  }
}

export default OrdersList;
