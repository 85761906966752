import React from 'react';
import PropTypes from 'prop-types';
import {
  SortableContainer,
} from 'react-sortable-hoc';

import CategoryItem from '../CategoryItem';

import s from '../../style.module.scss';

const CategoryList = ({
  items,
  expandCategory,
  activeCategory,
  changeMealProperty,
  changeCategoryName,
  changeNewCategoryValue,
  categoryInput,
  addCategory,
  onMealListSortEnd,
  ...props
}) => {
  return (
    <ul className={s.categoryList}>
      {items.map((category, index) => (
        <CategoryItem
          key={category.key}
          index={index}
          category={category}
          expandCategory={expandCategory}
          activeCategory={activeCategory}
          changeMealProperty={changeMealProperty}
          changeCategoryName={changeCategoryName}
          changeNewCategoryValue={changeNewCategoryValue}
          categoryInput={categoryInput}
          addCategory={addCategory}
          onSortEnd={onMealListSortEnd}
          {...props}
        />
      ))}
    </ul>
  );
};

CategoryList.propTypes = {
  items: PropTypes.array.isRequired,
  expandCategory: PropTypes.func.isRequired,
  activeCategory: PropTypes.any, // TODO: Add one of null or number PropType
  changeMealProperty: PropTypes.func.isRequired,
  changeCategoryName: PropTypes.func.isRequired,
  changeNewCategoryValue: PropTypes.func.isRequired,
  categoryInput: PropTypes.string.isRequired,
  addCategory: PropTypes.func.isRequired,
  onMealListSortEnd: PropTypes.func.isRequired,
  updateMealProperty: PropTypes.func.isRequired,
};

export default SortableContainer(CategoryList);
