import React from 'react';
import Icon from '@material-ui/core/Icon';
import {
  SortableHandle,
} from 'react-sortable-hoc';

import s from '../../style.module.scss';

const DragHandle = () => (
  <Icon className={s.restaurantIcon}>drag_handle</Icon>
);

export default SortableHandle(DragHandle);