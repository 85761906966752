import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Layout from '../../layout/Main';
import Title from './components/Title';
import { MealItem, BaseItem } from '../../common/ListItem';
import Form from './components/Form';
import Message from '../../common/Message';

import { displaySnackbar as displaySnackbarAction } from '../../../redux/actions/app';
import { isRestaurantOpen } from '../../../functions/utils';
import history from '../../../history';
import { paths } from '../../../config';

import s from './style.module.scss';

class Recap extends Component {
  static propTypes = {
    order: PropTypes.array.isRequired,
  };

  state = {
    restaurantClosed: false,
  };

  componentWillMount() {
    const {
      activeRestaurant, 
    } = this.props;
    
    if (activeRestaurant.openingHours === undefined) {
      history.push(paths.homepage);
      return;
    }

    if (!isRestaurantOpen(activeRestaurant.openingHours)) {
      this.setState({
        restaurantClosed: true,
      });
    } 
  }

  get orderTotal() {
    return this.props.order.reduce((acc, cur) => acc + (cur.price * (cur.amount || 0)), 0);
  }

  get mealCount() {
    return this.props.order
      .filter(item => !item.isDrink)
      .reduce((acc, cur) => acc + (cur.amount || 0), 0);
  }

  render() {
    const { order } = this.props;
    const { restaurantClosed } = this.state;

    const coverPrice = 10;

    return (
      <Layout image>
        {restaurantClosed ?
          <Message
            icon="sentiment_dissatisfied"
            message="Restaurace je nyní bohužel zavřená"
          />
          :
          <div className={s.wrapper}>
            <section className={s.recapContainer}>
              <Title
                icon="shopping_basket"
                text="Rekapitulace objednávky"
              />
              {order.length ?
                <ul className={s.recapOrderList}>
                  {order.map(meal => (
                    <MealItem meal={meal} key={meal.name}/>
                  ))}
                  <BaseItem title="Obaly" price={coverPrice * this.mealCount} amount={this.mealCount}/>
                  <BaseItem title="Doprava" price="zdarma"/>
                  <hr />
                  <BaseItem title="Celkem" price={(this.mealCount * coverPrice) + this.orderTotal}/>
                </ul>
                :
                <span className={s.orderEmpty}>Objednávka je prázdná</span>
              }
            </section>
            <section className={s.detailsContainer}>
              <Form
                mealCount={this.mealCount}
                orderTotal={(this.mealCount * coverPrice) + this.orderTotal}
                coverPrice={coverPrice}
              />
            </section>
          </div>
        }
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { app } = state;

  return {
    order: app.order,
    activeRestaurant: app.activeRestaurant,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    displaySnackbar(message) {
      dispatch(displaySnackbarAction(message));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Recap);

