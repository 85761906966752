import React from 'react';
import PropTypes from 'prop-types';

import s from './style.module.scss';

const Title = ({ children }) => (
  <h1 className={s.title}>{children}</h1>
);

Title.propTypes = {
  children: PropTypes.any.isRequired,
};

export default Title;
