import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import s from './style.module.scss';

const Base = ({ title, description, price, children, plainAmount }) => {
  if (children && plainAmount) throw new Error('Children and amount props cannot both specified on Base component !');

  return (
    <li className={s.mealsItem}>
      <div className={s.mealsItemText}>
        <span className={s.mealTitle}>{title}</span>
        {description && <span className={s.mealDescription}>{description}</span>}
      </div>
      <span className={s.mealsItemPrice}>{price}{Number.isInteger(price) && ',- Kč'}</span>
      <div className={s.mealsItemAmount}>
        {children !== undefined && children}
        {plainAmount !== undefined &&
          <span className={s.mealAmount}>{plainAmount}</span>
        }
      </div>
    </li>
  );
};

Base.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  description: PropTypes.string,
  plainAmount: PropTypes.number,
  children: PropTypes.any,
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Base);
