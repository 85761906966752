export default [
  // http://regiony.kurzy.cz/psc/praha/radotin-cast/
  // PSC 153 00
  'Achátová',
  'Ametystová',
  'Býšovská',
  'Častonická',
  'Čedičová',
  'Dehtínská',
  'Felberova',
  'Grafitová',
  'Hadravská',
  'Horymírovo náměstí',
  'Chatová',
  'Chrobolská',
  'Jarkovská',
  'Jarmily Novotné',
  'Jaspisová',
  'Javořická',
  'Jelenovská',
  'Ježdíkova',
  'Josefa Kočího',
  'K Berounce',
  'K cementárně',
  'K lázním',
  'K přívozu',
  'K višňovce',
  'Kalabisova',
  'Karlická',
  'Ke Zděři',
  'Kejnická',
  'Kolová',
  'Kraslická',
  'Ledvická',
  'Lošetická',
  'Loučanská',
  'Macháčkova',
  'Matějovského',
  'Minerální',
  'Na Benátkách',
  'Na betonce',
  'Na Cikánce',
  'Na Rymáni',
  'Na Říháku',
  'Na Viničkách',
  'Na Výšince',
  'náměstí Osvoboditelů',
  'Nýřanská',
  'Otěšínská',
  'Otínská',
  'Plánická',
  'Pod Klapicí',
  'Pod Lahovskou',
  'Pod Velkým hájem',
  'Prvomájová',
  'Přeštínská',
  'Příbramská',
  'Radkovská',
  'Safírová',
  'Sídliště',
  'Slavičí',
  'Slinková',
  'Sobětická',
  'Solná',
  'Stadiónová',
  'Staňkovka',
  'Strážovská',
  'Strunkovská',
  'Šárovo kolo',
  'Štěrková',
  'Tachovská',
  'Topasová',
  'Týřovická',
  'U drážky',
  'U Jankovky',
  'U sanatoria',
  'U starého stadiónu',
  'U učiliště',
  'U vápenky',
  'U Viniček',
  'V Edenu',
  'V Parníku',
  'V sudech',
  'Václava Balého',
  'Vápenná',
  'Věštínská',
  'Vinohrady',
  'Vojetická',
  'Vrážská',
  'Vykoukových',
  'Výpadová',
  'Zbynická',
  'Zderazská',
  'Zdická',
  'Zítkova',
  'Živcová',
]