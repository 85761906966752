import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';

import s from './style.module.scss';

const Select = ({ label, options, onChange, value }) => (
  <div className={s.selectContainer}>
    <span className={s.selectLabel}>{label}</span>
    <select onChange={(e) => onChange(e.target.value)} className={s.select} value={value}>
      {options.map(o => (
        <option value={o.value} key={o.value}>{o.label}</option>
      ))}
    </select>
    <Icon className={s.selectArrow}>arrow_drop_down</Icon>
  </div>
);

Select.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default Select;
