import {
  getDatabaseRef,
  log,
} from './utils';

export async function observeEnabled (fn) {
  try {
    const ref = getDatabaseRef('drinks/enabled');
    ref.on('value', async function (snapshot) {
      fn(snapshot.val());
    });
    return ref;
  } catch (e) {
    log(e);
    return false;
  }
}

export async function observeTitle (fn) {
  try {
    const ref = getDatabaseRef('drinks/title');
    ref.on('value', async function (snapshot) {
      fn(snapshot.val());
    });
    return ref;
  } catch (e) {
    log(e);
    return false;
  }
}

export async function updateEnabled (value) {
  try {
     await getDatabaseRef(`drinks`).update({ enabled: value });
     return true;
  } catch (e) {
    log(e);
    return false;
  }
}

export async function updateTitle (value) {
  try {
    await getDatabaseRef(`drinks`).update({ title: value });
    return true;
  } catch (e) {
    log(e);
    return false;
  }
}

export async function updateDrinks (drinks) {
  try {
    await getDatabaseRef(`drinks`).update({ items: drinks });
    return true;
  } catch (e) {
    log(e);
    return false;
  }
}

export async function getDrinks () {
  try {
    const snapshot = await getDatabaseRef(`drinks/items`).once('value');
    const value = snapshot.val();

    return value === null ? [] : value;
  } catch (e) {
    log(e);
    return true;
  }
}

export async function getDrinksObject () {
  try {
    const snapshot = await getDatabaseRef(`drinks`).once('value');
    const value = snapshot.val();

    // TODO: Refactor so this bridge can be removed
    return {
      meals: value.items.map(item => ({ ...item, isDrink: true })),
      name: value.title,
      disabled: !value.enabled,
    }
  } catch (e) {
    log(e);
    return true;
  }
}