import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

import s from './style.module.scss';

import { getClass } from '../../../functions/utils';

const getSize = (xl, lg, md, sm) => xl ? 'xl' : lg ? 'lg' : md ? 'md' : 'sm';

const DefaultButton = ({
  children,
  xl,
  lg,
  md,
  sm,
  onClick,
  classes = {},
  disabled = false,
  type = 'button',
  modal = false,
}) => (
  <Button
    classes={{
      root: `${s.root} ${s[getSize(xl, lg, md, sm) + 'Button']} ${getClass(classes.root)} ${getClass(modal, s.modal)}`,
      label: `${s.label} ${s[getSize(xl, lg, md, sm) + 'Label']} ${getClass(classes.label)} ${getClass(modal, s.modalLabel)}`,
    }}
    disabled={disabled}
    onClick={onClick}
    type={type}
  >
    {children}
  </Button>
);

DefaultButton.propTypes = {
  children: PropTypes.any.isRequired,
  onClick: PropTypes.func,
  classes: PropTypes.object,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  lg: PropTypes.bool,
  md: PropTypes.bool,
  sm: PropTypes.bool,
  xl: PropTypes.bool,
  modal: PropTypes.bool,
};

export default DefaultButton;
