import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as firebase from 'firebase';
import Icon from '@material-ui/core/Icon';

import Logo from '../../common/Logo';
import { paths } from '../../../config';
import history from '../../../history';
import { getModal, observeUserState } from '../../../functions/api';

import s from './style.module.scss';

class AdminLayout extends Component {
  static propTypes = {
    children: PropTypes.any.isRequired,
  };

  userObserver = null;

  constructor(props) {
    super(props);

    this.state = {
      loggedIn: true,
      modal: null,
    };

    this.signOutUser = this.signOutUser.bind(this);
  }

  async componentDidMount () {
    const modal = await getModal()

    this.setState({ modal });

    this.userObserver = observeUserState(user => {
      if (!user) {
        history.push(paths.adminLogin);
        return;
      }

      this.setState({ loggedIn: true });
    });
  }

  componentWillUnmount () {
    if (this.userObserver) this.userObserver();
  }

  signOutUser() {
    firebase.auth().signOut().then(() => {
      this.setState({ loggedIn: false });
      history.push(paths.adminLogin);
    }).catch(function(error) {
      console.error('Error has occured ...', error);
    });
  }

  render() {
    const { children } = this.props;
    const { loggedIn, modal } = this.state;

    return (
      <div className={s.wrapper}>
        {loggedIn ?
          <div className={s.container}>
            <div className={s.sidebar}>
              <Logo to={paths.adminDashboard} className={s.logo} />
              <nav className={s.menu}>
                <ul className={s.menuList}>
                  <li className={s.menuItem} onClick={() => history.push(paths.adminDashboard)}>
                    <Icon>home</Icon>
                    <span className={s.menuItemText}>Dashboard</span>
                  </li>
                  <li className={s.menuItem} onClick={() => history.push(paths.adminOrdersList)}>
                    <Icon>attach_money</Icon>
                    <span className={s.menuItemText}>Objednávky</span>
                  </li>
                  <li className={s.menuItem} onClick={() => history.push(paths.adminRestaurant)}>
                    <Icon>local_dining</Icon>
                    <span className={s.menuItemText}>Restaurace</span>
                  </li>
                  <li className={s.menuItem} onClick={() => history.push(paths.adminDrinks)}>
                    <Icon>local_bar</Icon>
                    <span className={s.menuItemText}>Nápoje</span>
                  </li>
                  <li 
                    style={{ color: modal && modal.enabled ? '#2ecc71' : '#fff' }} 
                    className={s.menuItem} 
                    onClick={() => history.push(paths.adminModal)}
                  >
                    <Icon>alarm</Icon>
                    <span className={s.menuItemText}>Dialog</span>
                  </li>
                  <li className={s.menuItem} onClick={this.signOutUser}>
                    <Icon>exit_to_app</Icon>
                    <span className={s.menuItemText}>Odhlásit</span>
                  </li>
                </ul>
              </nav>
            </div>
            <div className={s.content}>
              {children}
            </div>
          </div>
          :
          <span>You are not authorized to visit this page</span>
        }
      </div>
    );
  }
}

export default AdminLayout;
