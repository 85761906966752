import React, { Component } from 'react';
import { connect } from 'react-redux';
import update from 'immutability-helper';

import CircularProgress from '@material-ui/core/CircularProgress';
import Switch from '@material-ui/core/Switch';

import Layout from '../../../layout/Admin';
import TextField from '../../../common/TextField';
import Button from '../../../common/Button';

import { displaySnackbar } from '../../../../redux/actions/app';
import {
    updateModal,
    getModal,
} from '../../../../functions/api';

import s from './style.module.scss';

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      modal: null,
    };

    this.submitChanges = this.submitChanges.bind(this);
  }

  async componentDidMount() {
    const modal = await getModal()

    this.setState({
      loaded: true,
      modal,
    });
  }

  async submitChanges() {
    const {
      modal,
    } = this.state;

    const {
      displaySnackbar,
    } = this.props;

    await updateModal(modal);

    displaySnackbar('Uloženo');
  }

  render() {
    const {
      modal,
      loaded,
    } = this.state;

    return (
      <Layout>
        {!loaded ?
            <div className={s.loaderContainer}>
                <CircularProgress size={200} style={{ color: '#fff' }}/>
            </div>
            :
            <div>
                <div className={s.textContainer}>
                  <div>
                    <p className={s.enabledText}>Aktivní:</p>
                    <Switch
                      checked={modal.enabled}
                      onChange={event => {
                          this.setState(s => update(s, {
                              modal: { $toggle: ['enabled'] },
                          }))
                      }}
                    />
                  </div>
                    <TextField
                        multiline
                        label="Nadpis dialogu"
                        value={modal.title}
                        onChange={value => {
                            this.setState(s => update(s, {
                                modal: { title: { $set: value }},
                            }))
                        }}
                    />
                    <TextField
                        multiline
                        label="Text dialogu"
                        value={modal.content}
                        onChange={value => {
                            this.setState(s => update(s, {
                                modal: { content: { $set: value }},
                            }))
                        }}
                    />
                </div>
                <div className={s.buttonContainer}>
                    <Button md onClick={this.submitChanges}>Uložit změny</Button>
                </div>
            </div>
        }
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    displaySnackbar(message) {
      dispatch(displaySnackbar(message));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);