import React, { Component } from 'react';
import { connect } from 'react-redux';
import Icon from '@material-ui/core/Icon';

import { resetOrder } from '../../../redux/actions/app';

import Layout from '../../layout/Main';

import s from './style.module.scss';

class Confirmation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      canAccess: true,
    }
  }

  componentWillMount() {
    const { orderComplete } = this.props;

    if(orderComplete) {
      this.setState({ canAccess: true });
    }
  }

  componentDidMount() {
    const { resetOrder } = this.props;

    resetOrder();
    localStorage.removeItem('order');
  }

  render() {
    const { canAccess } = this.state;

    return (
      <Layout>
        {canAccess ?
          <div className={s.container}>
            <div className={s.titleContainer}>
              <Icon classes={{ root: s.icon }}>local_shipping</Icon>
              <h1 className={s.title}>Po obdržení potrvrzovacího e-mailu je objednávka úspěšně dokončena.</h1>
            </div>
            <p className={s.text}>Maximální doba doručení: 75 minut</p>
          </div>
          :
          <div className={s.unauthorizedAccessContainer}>
            <Icon className={s.unauthorizedAccessIcon}>error</Icon>
            <span className={s.unauthorizedAccessText}>Někde nastala chyba ...</span>
          </div>
        }
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { app } = state;
  return {
    order: app.order,
    orderComplete: app.orderComplete,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetOrder() {
      dispatch(resetOrder());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);
