import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';

import s from './style.module.scss';

const Message = ({ icon, message }) => {
  return (
    <div className={s.unauthorizedAccessContainer}>
      <Icon className={s.unauthorizedAccessIcon}>{icon}</Icon>
      <span className={s.unauthorizedAccessText}>{message}</span>
    </div>
  )
};

Message.propTypes = {
  icon: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default Message;