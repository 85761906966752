import React, { Component } from 'react';
import { connect } from 'react-redux';
import update from 'immutability-helper';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';

import Layout from '../../../layout/Admin';
import Title from '../components/Title';
import TextInput from '../../../common/TextField';

import { displaySnackbar } from '../../../../redux/actions/app';
import {
  getDrinks,
  observeEnabled,
  observeTitle,
  updateDrinks,
  updateEnabled,
  updateTitle
} from '../../../../api/drinks';

import s from './style.module.scss';

class Drinks extends Component {
  enabledObserver = null;
  titleObserver = null;

  initialDrinks = null;
  initialTitle = null;

  constructor(props) {
    super(props);

    this.state = {
      title: null,
      drinks: null,
      enabled: false,
    };

    this.toggleCategoryEnabled = this.toggleCategoryEnabled.bind(this);
    this.updateField = this.updateField.bind(this);
    this.toggleItemEnabled = this.toggleItemEnabled.bind(this);
    this.removeDrink = this.removeDrink.bind(this);
    this.resetDrinks = this.resetDrinks.bind(this);
    this.updateDrinks = this.updateDrinks.bind(this);
    this.updateTitle = this.updateTitle.bind(this);
    this.addDrink = this.addDrink.bind(this);
  }

  async componentDidMount() {
    const { title } = this.state;

    const drinks = await getDrinks();

    this.setState({
      drinks,
    });

    this.initialDrinks = drinks;

    this.enabledObserver = observeEnabled(enabled => {
      this.setState({ enabled });
    });

    this.titleObserver = observeTitle(updatedTitle => {
      if (title === null) {
        this.initialTitle = updatedTitle;
      }

      this.setState({ title: updatedTitle });
    });
  }

  async toggleCategoryEnabled (event) {
    const { displaySnackbar } = this.props;
    const checked = event.target.checked;

    if (await updateEnabled(checked)) {
      displaySnackbar(`Nápoje úspěšně ${!checked ? 'de' : ''}aktivovány`);
      return;
    }

    displaySnackbar(`Nápoje nemohly být ${!checked ? 'de' : ''}aktivovány! Zkuste to prosím znovu.`);
  }

  updateField (fieldName, index) {
    return value => {
      this.setState(state => {
        return update(state, {
          drinks: { [index]: { [fieldName]: { $set: value }}},
        });
      });
    }
  }

  toggleItemEnabled (index) {
    return event => {
      const checked = event.target.checked;

      this.setState(state => {
        return update(state, {
          drinks: { [index]: { disabled: { $set: !checked }}},
        });
      });
    }
  }

  removeDrink (index) {
    this.setState(state => update(state, {
      drinks: { $splice: [[index, 1]] },
    }));
  }

  resetDrinks () {
    this.setState(state => update(state, {
      drinks: { $set: this.initialDrinks },
      title: { $set: this.initialTitle },
    }));
  }

  async updateDrinks () {
    const { drinks, title } = this.state;
    const { displaySnackbar } = this.props;

    if (await updateDrinks(drinks) && await updateTitle(title)) {
      displaySnackbar(`Nápoje úspěšně aktualizovány`);
      return;
    }

    displaySnackbar(`Nápoje nemohly být aktualizovány! Zkuste to prosím zonvu.`);
  }

  updateTitle (value) {
    this.setState({
      title: value,
    });
  }

  addDrink () {
    const newDrink = {
      name: 'Název nápoje',
      description: 'Popis nápoje',
      disabled: false,
      isDrink: true,
      price: 0,
    };

    this.setState(state => update(state, {
      drinks: { $push: [newDrink] },
    }));
  }

  render() {
    const {
      drinks,
      enabled,
      title,
    } = this.state;

    return (
      <Layout>
        {drinks === null ?
          <div className={s.loaderContainer}>
            <CircularProgress size={200} style={{ color: '#fff' }}/>
          </div>
          :
          <>
            <>
              <div className={s.headerContainer}>
                <Title>Nápoje</Title>
                <Switch
                  checked={enabled}
                  onChange={this.toggleCategoryEnabled}
                  color="primary"
                  classes={{ root: s.headerSwitch }}
                />
              </div>
              <TextInput
                label="Název kategorie"
                onChange={this.updateTitle}
                value={title || ''}
              />
            </>
            {drinks.length === 0
              ?
              <Typography
                align="center"
                variant="headline"
                classes={{
                  root: s.noDrinksText,
                }}
              >Žádné nápoje nebyly zatím přidány!</Typography>
              :
              <ul className={s.drinksList}>
                {drinks.map((drink, index) => (
                  <li className={s.drinkItem} key={index}>
                    <Switch
                      checked={!drink.disabled}
                      onChange={this.toggleItemEnabled(index)}
                      color="primary"
                      classes={{ root: s.drinkSwitch }}
                    />
                    <TextInput
                      label="Název"
                      onChange={this.updateField('name', index)}
                      value={drink.name}
                      classes={{
                        container: s.textInputContainer
                      }}
                    />
                    <TextInput
                      label="Popis"
                      onChange={this.updateField('description', index)}
                      value={drink.description}
                      classes={{
                        container: s.textInputContainer
                      }}
                    />
                    <TextInput
                      label="Cena"
                      onChange={value => this.updateField('price', index)(parseInt(value, 10))}
                      value={String(drink.price)}
                      classes={{
                        container: s.textInputContainer
                      }}
                    />
                    <IconButton
                      classes={{ root: s.drinkRemoveBtn }}
                      onClick={() => this.removeDrink(index)}
                    >
                      <Icon>clear</Icon>
                    </IconButton>
                  </li>
                ))}
              </ul>
            }
            <div className={s.btnsContainer}>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.addDrink}
                >Přidat nápoj</Button>
              </div>
              <div>
                <Button
                  variant="contained"
                  className={s.cancelBtn}
                  onClick={this.resetDrinks}
                >Zrušit</Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.updateDrinks}
                >Uložit</Button>
              </div>
            </div>
          </>
        }

      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    displaySnackbar(message) {
      dispatch(displaySnackbar(message));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Drinks);