import {
  getDatabaseRef,
  log,
} from './utils';

export async function getLastOrderId () {
  try {
    const snapshot = await getDatabaseRef(`settings/lastOrderId`).once('value');
    return snapshot.val();
  } catch (e) {
    log(e);
    return false;
  }
}

export async function incrementLastOrderId () {
  try {
    const previousOrderId = parseInt(await getLastOrderId(), 10);
    const nextOrderId = String(previousOrderId + 1).padStart(5, '0');

    await getDatabaseRef(`settings`).update({ lastOrderId: nextOrderId });
    return nextOrderId;
  } catch (e) {
    log(e);
    return false;
  }
}