import * as firebase from 'firebase';

import { isRestaurantOpen } from './utils';
import { incrementLastOrderId } from '../api/settings';

/**
 * Returns CSV like string of all unique customers based on their email address
 * 
 * @example
 * Name,Email,Phone
 * "John Doe","joh@email.com","123 456 789"
 * "John Doe","joh@email.com","123 456 789"
 */
export async function getCustomersTable (header = '"Jméno","Email","Telefon"') {
  try {
    const request = await firebase
      .database()
      .ref('/orders')
      .once('value')

    const data = request.val()

    return Object.entries(data)
      .reduce((acc, [id, content]) => {
        const {
          name,
          email,
          phone,
        } = content.form
      
        if (acc.indexOf(email) > -1) {
          console.log(`🧹 Skipping email: ${content.form.email}`)

          return acc
        }

        console.log(`✅ ${(acc.match(/\n/g) || '').length} - Customer with email ${email} added (${name}, ${phone})`)

        return acc + `\n"${name}","${email}","${phone}"`
      }, header)

  } catch (e) {
    console.error(e)
  }
}

export function observeUserState (fn) {
  return firebase.auth().onAuthStateChanged(fn);
}

/**
 * Returns an array of restaurants
 *
 * @return {Promise.<Array>}
 */
export async function getRestaurants() {
  try {
    const request = await firebase
      .database()
      .ref('/restaurants')
      .once('value');

    const restaurants = request.val();

    return restaurants.map(restaurant => {
      return {
        ...restaurant,
        isOpen: isRestaurantOpen(restaurant.openingHours),
      }
    });
  } catch (exception) {
    // TODO: Handle error
    console.error('Could not retrieve restaurants! ', exception);
  }
}

export async function updateRestaurants(restaurants) {
  try {
    await firebase
      .database()
      .ref('/restaurants')
      .set(restaurants);

    return await getRestaurants();
  } catch (exception) {
    // TODO: Handle exception
    console.error('Could not update restaurants! ', exception);
  }
}

export async function addOrder(order) {
  try {
    await firebase
      .database()
      .ref('/orders/' + await incrementLastOrderId())
      .set(order);

  } catch (exception) {
    console.error('Could not add order! ', exception);
  }
}

export async function updateHomepageText(text) {
  try {
    await firebase
      .database()
      .ref('/text')
      .set(text);
  } catch (exception) {
    console.error(exception);
  }
}

export async function getHomepageText() {
  try {
    const request = await firebase
      .database()
      .ref('/text')
      .once('value');

    return request.val();
  } catch (exception) {
    console.error(exception);
  }
}

export async function getLastOrders (n) {
  try {
    const request = await firebase 
      .database()
      .ref('/orders')
      .limitToLast(n)
      .once('value');
  
    const value = request.val();

    // Convert response object to array and attach its ID
    return Object.keys(value)
      .sort((a, b) => parseInt(a) > parseInt(b) ? -1 : 1)
      .map(key => ({ ...value[key], id: key }))

  } catch (e) {
    console.error(e);
  }
}

const MODAL_DEFAULT_VALUE = {
  enabled: false,
  title: '',
  content: '',
}

export async function getModal () {
  try {
    const request = await firebase
      .database()
      .ref('/modal')
      .once('value')

    const value = request.val()

    return value || MODAL_DEFAULT_VALUE
  } catch (e) {
    console.error(e)
  }
}

export async function updateModal ( modal ) {
  try {
    await firebase
      .database()
      .ref('/modal')
      .set(modal)
  } catch (e) {
    console.error(e)
  }
}