import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { paths } from '../../../../../config';

import Logo from '../../../../common/Logo';
import Basket from '../../../../common/Basket';
import CourierContact from '../../../../common/CourierContact';

import s from './style.module.scss';

const Header = ({ activeRestaurant, image }) => {
  return (
    <div>
      <header className={s.header}>
        <div className={s.headerContent}>
          <Logo displaySocialIcons />
          <div className={s.headerSidebar}>
            <CourierContact />
            <Basket color="#fff" classes={{ link: s.basketLink }}/>
          </div>
        </div>
      </header>
      {image &&
      <section className={s.imageSection}>
        <Link to={paths.restaurant.replace(':id', activeRestaurant.url)} className={s.link}>
          <p className={s.imageText}>{activeRestaurant.name}</p>
        </Link>
        <span className={s.imageOverlay}/>
      </section>
      }
    </div>
  );
};

Header.propTypes = {
  activeRestaurant: PropTypes.object.isRequired,
  image: PropTypes.bool,
};

function mapStateToProps(state) {
  const { app, data } = state;

  return {
    activeRestaurant: data.find(restaurant => restaurant.url === app.activeRestaurant.url) || {},
  };
}

export default connect(mapStateToProps)(Header);
