import React from 'react';

import {
  getOrderTotal,
  getPaymentMethodLabel,
} from '../../../../../../functions/utils';

import s from './style.module.css';

const OrderItem = ({ order }) => {
    return (
    <div>
      <h1 className={s.orderTitle}>Objednávka {order.id} z restaurace {order.restaurant.name}</h1>
      <h2 className={s.orderSubtitle}>Klient:</h2>
      <p>Jméno: {order.form.name}</p>
      <p>Adresa: {order.form.street}, {order.form.zip} {order.form.city}</p>
      <p>Telefon: {order.form.phone}</p>
      <p>Email: {order.form.email}</p>
      <p>Způsob platby: {getPaymentMethodLabel(order.form.paymentMethod)}</p>
      <h2 className={s.orderSubtitle}>Objednávka</h2>
      {order.order.map((item, index) => (
        <p key={index}>{item.amount}x {item.name}</p>
      ))}
      <h2 className={s.orderPrice}>Cena celkem: {getOrderTotal(order.order)},-</h2>
      <hr />
    </div>
  );
}

export default OrderItem;
