import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';

import Title from '../Title';
import TextField from '../../../../common/TextField';
import Button from '../../../../common/Button';
import Select from '../../../../common/Select';

import streets from '../../../../../assets/streets';
import history from '../../../../../history';
import { paths } from '../../../../../config';
import { displaySnackbar, finishOrder } from '../../../../../redux/actions/app';
import { addOrder } from '../../../../../functions/api';
import { isRestaurantOpen } from '../../../../../functions/utils';

import s from './style.module.scss';

class Form extends Component {
  static propTypes = {
    mealCount: PropTypes.number.isRequired,
    orderTotal: PropTypes.number.isRequired,
    coverPrice: PropTypes.number.isRequired,
    order: PropTypes.array.isRequired,
    activeRestaurant: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const emptyFormState = {
      name: '',
      phone: '',
      email: '',
      city: 'Radotín',
      street: '',
      zip: '',
      note: '',
      paymentMethod: 'cash',
    };

    const storedForm = JSON.parse(localStorage.getItem('deliveryInformation'));

    const currentForm = !storedForm ? emptyFormState : {
      ...storedForm,
      note: '',
      paymentMethod: 'cash',
    };

    this.state = {
      form: currentForm,
      submittingForm: false,
    };

    this.updateField = this.updateField.bind(this);
    this.submitOrder = this.submitOrder.bind(this);
  }

  updateField(name) {
    return (value) => {
      this.setState(state => {
        return {
          form: {
            ...state.form,
            [name]: value,
          }
        }
      })
    }
  }

  async submitOrder(event) {
    const { form } = this.state;
    const {
      displaySnackbar,
      mealCount,
      orderTotal,
      finishOrder,
      order,
      activeRestaurant,
    } = this.props;

    event.preventDefault();

    if (!isRestaurantOpen(activeRestaurant.openingHours)) {
      displaySnackbar('Restaurace je nyní bohužel zavřená');
      return;
    }

    if (!/^((\+|00)42[01]|00)?(\s?\d{3}){3}$/.test(form.phone)) {
      displaySnackbar('Zadejte platné telefonní číslo!');
      return;
    }

    if(mealCount === 0) {
      displaySnackbar('Vaše objednávka je prázdná');
      return false;
    } else if (orderTotal < 100) {
      displaySnackbar('Minimální hodnota objednávky je 100,-');
      return false;
    }

    this.setState({ submittingForm: true });

    await addOrder({
      timestamp: Date.now(),
      form,
      order,
      restaurant: {
        name: activeRestaurant.name,
        email: activeRestaurant.email,
      },
    });

    finishOrder();
    localStorage.setItem('deliveryInformation', JSON.stringify(form));
    this.setState({ submittingForm: false });

    history.push(paths.confirmation);

    return false;
  }

  render() {
    const { form, submittingForm } = this.state;

    return (
      <form className={s.detailsForm} onSubmit={this.submitOrder}>
        <Title
          icon="person"
          text="Detail doručení"
        />
        <TextField
          label="Jméno, příjmení"
          onChange={this.updateField('name')}
          value={form.name}
          required
          classes={{
            container: s.inputContainer,
          }}
        />
        <TextField
          label="Telefon"
          onChange={this.updateField('phone')}
          value={form.phone}
          type="tel"
          required
          classes={{
            container: s.inputContainer,
          }}
        />
        <TextField
          label="Email"
          onChange={this.updateField('email')}
          value={form.email}
          required
          type="email"
          classes={{
            container: s.inputContainer,
          }}
        />
        <TextField
          label="Město"
          onChange={() => true}
          value={form.city}
          helperText="Po tel. dohodě rozvoz i mimo Radotín"
          required
          disabled
          classes={{
            container: s.inputContainer,
          }}
        />
        <TextField
          label="Ulice, číslo popisné"
          onChange={this.updateField('street')}
          value={form.street}
          required
          autocomplete={streets}
          classes={{
            container: s.inputContainer,
          }}
        />
        <TextField
          label="Poštovní směrovací číslo"
          onChange={this.updateField('zip')}
          value={form.zip}
          required
          classes={{
            container: s.inputContainer,
          }}
        />
        <Select
          label="Způsob platby"
          onChange={this.updateField('paymentMethod')}
          value={form.paymentMethod}
          options={[
            { value: 'card', label: 'Kreditní karta' },
            { value: 'cash', label: 'Hotově' },
          ]}
        />
        <TextField
          label="Poznámka"
          onChange={this.updateField('note')}
          value={form.note}
          multiline
          classes={{
            container: s.inputContainer,
          }}
        />
        <div className={s.buttonContainer}>
          <Button md type="submit" classes={{ root: s.submitButton }}>{submittingForm ? <CircularProgress style={{ color: '#fff' }} size={30}/> : 'Odeslat objednávku'}</Button>
        </div>
      </form>
    );
  }
}

function mapStateToProps(state) {
  const { app } = state;

  return {
    activeRestaurant: app.activeRestaurant,
    order: app.order,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    displaySnackbar(message) {
      dispatch(displaySnackbar(message));
    },
    finishOrder() {
      dispatch(finishOrder());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form);
