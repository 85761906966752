import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from 'react-autocomplete';

import { getClass } from '../../../functions/utils';

import s from './style.module.scss';

const menuStyle = {
  zIndex: 99999,
  borderRadius: '3px',
  boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
  background: '#fff',
  fontSize: '90%',
  position: 'fixed',
  overflow: 'auto',
  maxHeight: '50%',
};

const TextInput = ({
  label,
  multiline,
  autocomplete,
  onChange,
  helperText,
  type = 'text',
  classes = {},
  ...props,
}) => (
  <div className={getClass(classes.container)}>
    <span className={s.inputLabel}>{label}</span>
    {autocomplete ?
      <Autocomplete
        inputProps={{
          className: s.inputField,
          ...props,
        }}
        getItemValue={(item) => item}
        items={autocomplete}
        value={props.value}
        onChange={(e) => onChange(e.target.value)}
        onSelect={(val) => onChange(val)}
        sortItems={(a, b) => a.localeCompare(b)}
        menuStyle={menuStyle}
        shouldItemRender={(item, value) => item.toLowerCase().startsWith(value.toLowerCase())}
        renderItem={(item, isHighlighted) => (
          <span
            key={item}
            className={`${s.hint} ${getClass(isHighlighted, s.hintHighlighted)}`}
          >{item}</span>
        )}
      />
      :
      <div>
        {multiline ?
          <textarea
            className={`${s.inputField} ${s.inputTextarea} ${getClass(classes.input)}`}
            onChange={(e) => onChange(e.target.value)}
            {...props}
          >{props.value}</textarea>
          :
          <input
            type={type}
            className={`${s.inputField} ${getClass(classes.input)}`}
            onChange={(e) => onChange(e.target.value)}
            {...props}
          />
        }
      </div>
    }
    {helperText && <span className={s.inputHelperText}>{helperText}</span>}
  </div>
);

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  multiline: PropTypes.bool,
  autocomplete: PropTypes.arrayOf(PropTypes.string),
  classes: PropTypes.object,
  helperText: PropTypes.bool,
};

export default TextInput;