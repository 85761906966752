import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';

import s from './style.module.scss';

const Title = ({ icon, text }) => (
  <div className={s.recapOrderTitle}>
    <Icon className={s.recapOrderTitleIcon}>{icon}</Icon>
    <p className={s.recapOrderTitleText}>{text}</p>
  </div>
);

Title.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default Title;