import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Badge from '@material-ui/core/Badge';
import { Link } from 'react-router-dom';

import { paths } from '../../../config';
import { getClass } from "../../../functions/utils";

import s from './style.module.scss';

const Basket = ({ order, color, classes = {} }) => (
  <Link to={paths.recap} className={getClass(classes.link)}>
    <Badge badgeContent={order.reduce((acc, cur) => cur.amount + acc, 0)} classes={{ badge: s.badge}}>
      <IconButton classes={{ root: s.basketIconButton }} style={{ color }}><Icon className={s.basketIcon}>shopping_basket</Icon></IconButton>
    </Badge>
  </Link>
);

Basket.propTypes = {
  order: PropTypes.array.isRequired,
  color: PropTypes.string.isRequired,
  classes: PropTypes.object,
};

function mapStateToProps(state) {
  const { app } = state;

  return {
    order: app.order,
  };
}

export default connect(mapStateToProps)(Basket);