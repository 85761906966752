import React, { Component } from 'react';
import * as firebase from 'firebase';

import TextField from '../../../common/TextField';
import Button from '../../../common/Button';

import history from '../../../../history';
import { paths } from "../../../../config";

import s from './style.module.scss';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      displayError: false,
    };

    this.updateInput = this.updateInput.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount () {
    firebase.auth().onAuthStateChanged(user => {
      if (user !== null) {
        history.push(paths.adminDashboard);
        return;
      }
    });
  }

  updateInput(name) {
    return (value) => {
      this.setState(state => {
        return {
          ...state,
          displayError: false,
          [name]: value,
        }
      });
    };
  }

  submitForm(event) {
    event.preventDefault();

    const {
      username,
      password,
    } = this.state;

    firebase.auth().signInWithEmailAndPassword(username, password)
      .then(data => {
        history.push(paths.adminDashboard);
      })
      .catch(error => {
        this.setState({ displayError: true });
      });

    return false;
  }

  render() {
    const {
      username,
      password,
      displayError,
    } = this.state;

    return (
      <div className={s.wrapper}>
        <div className={s.container}>
          <form className={s.loginBox} onSubmit={this.submitForm}>
            <h1 className={s.title}>Administrace</h1>
            <TextField
              label="Username"
              onChange={this.updateInput('username')}
              value={username}
              required
              classes={{
                input: s.input,
                container: s.inputContainer,
              }}
            />
            <TextField
              label="Password"
              onChange={this.updateInput('password')}
              value={password}
              required
              type="password"
              classes={{
                input: s.input,
                container: s.inputContainer,
              }}
            />
            {displayError && <span className={s.loginError}>Špatné uživatelské jméno nebo heslo</span>}
            <Button
              sm
              type="submit"
            >Submit</Button>
          </form>
        </div>
      </div>
    )
  }
}

export default Login;
