import React from 'react';
import PropTypes from 'prop-types';

import Header from './components/Header';
import MobileMenu from './components/MobileMenu';

import s from './style.module.scss';

const MainLayout = ({ children, image }) => (
  <div>
    <Header image={image}/>
    <main className={s.mainContainer}>
      {children}
    </main>
    <MobileMenu />
  </div>
);

MainLayout.propTypes = {
  children: PropTypes.any.isRequired,
  image: PropTypes.bool,
};

export default MainLayout;