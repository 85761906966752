import React, { Component } from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import Layout from '../../../layout/Admin';
import TextField from '../../../common/TextField';
import Button from '../../../common/Button';

import { displaySnackbar } from '../../../../redux/actions/app';
import { updateHomepageText, getHomepageText } from '../../../../functions/api';

import s from './style.module.scss';

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      deliveryText: '',
      additionalText: '',
    };

    this.updateText = this.updateText.bind(this);
    this.submitText = this.submitText.bind(this);
  }

  async componentWillMount() {
    const homepageTexts = await getHomepageText();

    this.setState({
      loaded: true,
      ...homepageTexts
    });
  }

  updateText(name) {
    return (value) => {
      this.setState({
        [name]: value,
      });
    }
  }

  async submitText() {
    const {
      deliveryText,
      additionalText,
    } = this.state;

    const {
      displaySnackbar,
    } = this.props;

    await updateHomepageText({
      deliveryText,
      additionalText,
    });

    displaySnackbar('Uloženo');
  }

  render() {
    const {
      deliveryText,
      additionalText,
      loaded,
    } = this.state;

    return (
      <Layout>
        {loaded ?
          <div>
            <div className={s.textContainer}>
              <TextField
                label="Číslo na kurýra"
                onChange={this.updateText('deliveryText')}
                value={deliveryText}
                multiline
              />
              <TextField
                classes={{
                  input: s.textInput,
                }}
                label="Text v hlavičce na hlavní stránce"
                onChange={this.updateText('additionalText')}
                value={additionalText}
                multiline
              />
            </div>
            <div className={s.buttonContainer}>
              <Button md onClick={this.submitText}>Uložit změny</Button>
            </div>
          </div>
          :
          <div className={s.loaderContainer}>
            <CircularProgress size={200} style={{ color: '#fff' }}/>
          </div>
        }

      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    displaySnackbar(message) {
      dispatch(displaySnackbar(message));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);