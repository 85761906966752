import {
  DISPLAY_MODAL,
  HIDE_MODAL,
  DISPLAY_SNACKBAR,
  HIDE_SNACKBAR,
  SET_ACTIVE_RESTAURANT,
  RESET_ORDER,
  REMOVE_MEAL_FROM_ORDER,
  ADD_MEAL_TO_ORDER,
  SET_ORDER,
  FINISH_ORDER,
} from '../constants';

const initialState = {
  activeRestaurant: {},
  modal: {
    open: false,
    title: '',
    description: '',
    onClose: null,
    onContinue: null,
  },
  snackbar: {
    message: '',
    isOpen: false,
    position: {
      vertical: 'top',
      horizontal: 'center',
    }
  },
  order: [],
  orderComplete: false,
};

function saveOrder(order) {
  const extendedOrder = {
    created: Date.now(),
    content: order,
  };

  localStorage.setItem('order', JSON.stringify(extendedOrder));
}

/**
 * Restaurant reducer
 * @param {object} state
 * @param {object} action
 * @return {object}
 */
export default function(state = initialState, action) {
  switch (action.type) {
    case DISPLAY_MODAL:
      return {
        ...state,
        modal: {
          open: true,
          ...action.modal,
        },
      };

    case HIDE_MODAL:
      return {
        ...state,
        modal: {
          ...state.modal,
          open: false,
        },
      };

    case DISPLAY_SNACKBAR:
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          message: action.message,
          isOpen: true,
        },
      };

    case HIDE_SNACKBAR:
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          isOpen: false,
        }
      };

    case SET_ACTIVE_RESTAURANT:
      return {
        ...state,
        activeRestaurant: action.restaurant,
      };

    case ADD_MEAL_TO_ORDER:
      const addItem = state.order.find(item => item.name === action.meal.name);

      if(addItem) {
        const addOrder = state.order.map(i => i.name === addItem.name ? { ...i, amount: i.amount + 1 } : i);

        saveOrder(addOrder);

        return {
          ...state,
          order: addOrder,
        }
      }

      const addOrder = [{ amount: 1, ...action.meal }, ...state.order];

      saveOrder(addOrder);

      return {
        ...state,
        order: addOrder,
      };

    case REMOVE_MEAL_FROM_ORDER:
      const removeItem = state.order.find(item => item.name === action.meal.name);
      const removeOrder = state.order
        .map(i => i.name === removeItem.name ? { ...i, amount: i.amount - 1 } : i)
        .filter(i => i.amount !== 0);

      saveOrder(removeOrder);

      if(removeItem) {
        return {
          ...state,
          order: removeOrder,
        }
      }

      return state;

    case RESET_ORDER:
      return {
        ...state,
        order: initialState.order,
        orderComplete: initialState.orderComplete,
      };

    case SET_ORDER:
      return {
        ...state,
        order: action.order,
      };

    case FINISH_ORDER:
      return {
        ...state,
        orderComplete: true,
      };

    default:
      return state;
  }
}
