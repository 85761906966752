import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { hideModal } from '../../../redux/actions/app';

import Button from '../../common/Button';

// TODO: Why doesn't onClose on Dialog work ?
const Modal = ({ open, onClose, onContinue, hideModal, title, description }) => (
  <Dialog
    open={open}
    fullWidth
  >
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{description}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={() => {
        hideModal();
        if (onClose) onClose();
      }} modal>Zrušit</Button>
      {onContinue &&
        <Button
          onClick={() => {
            onContinue();
            hideModal();
          }}
          autoFocus
        >Pokračovat</Button>}
    </DialogActions>
  </Dialog>
);

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onContinue: PropTypes.func,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    hideModal() {
      return dispatch(hideModal());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal);