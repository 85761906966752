import * as diacritics from 'diacritics';

/**
 * If defined returns given parameter otherwise returns an empty string
 *
 * @param { any } value
 * @param { string } className
 * @return { string } validClassName
 */
export function getClass(value, className = value) {
  return value !== undefined && value !== false ? className : '';
}

/**
 * Returns an index of current day
 * !!! Sunday has index 0 !!!
 *
 * @return { number } dayIndex
 */
export function getCurrentDayIndex() {
  const today = new Date();
  return today.getDay();
}

/**
 * Returns an information whether is restaurant open
 *
 * @param { Object } openingHours
 * @return { boolean } isOpen
 */
export function isRestaurantOpen(openingHours) {
  const todayOpening = openingHours[getCurrentDayIndex()];
  const currentTime = new Date();

  const open = Date.parse(`01/01/2011 ${todayOpening.from}`);
  const close = Date.parse(`01/01/2011 ${todayOpening.to}`);
  const current = Date.parse(`01/01/2011 ${currentTime.getHours()}:${currentTime.getMinutes()}`);

  return current > open && current < close;
}

/**
 * Parses given string so it can be used as element's ID
 *
 * @param { string } string
 * @return { string } parsedString
 */
export function parseString(string) {
  const updatedString = string
    .toLowerCase()
    .replace(/[/\s]/g, '');

  return diacritics.remove(updatedString);
}

/**
 * If without parameter returns parsedDate for current date
 * otherwise parses passed one
 *
 * @param { Date } date - Date object
 * @return { object } parsedDate - parsedDate
 */
export function parseDate(date = new Date()) {
  const pad = time => time >= 10 ? time : '0' + time;

  return {
    hours: pad(date.getHours()),
    minutes: pad(date.getMinutes()),
    seconds: pad(date.getSeconds()),
    day: pad(date.getDate()),
    month: pad(date.getMonth() + 1),
    year: pad(date.getFullYear())
  }
}

export function getDayName(index) {
  const days = [
    'Neděle',
    'Pondělí',
    'Úterý',
    'Středa',
    'Čtvrtek',
    'Pátek',
    'Sobota',
    'Neděle',
  ];

  return days[index];
}

export function generateId(length = 50, possibleCharacters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789') {
  function recursion(remaining = length, result = '') {
    if (remaining === 0) return result;

    const nextCharacter = possibleCharacters.charAt(Math.floor(Math.random() * possibleCharacters.length));

    return recursion(remaining - 1, result + nextCharacter);
  }

  return recursion();
}

// TODO: Store box price into a variable !!!
export function getOrderTotal (order) {
  return order.reduce((acc, cur) => {
    const boxPrice = !cur.isDrink ? cur.amount * 10 : 0;
    const itemsPrice = cur.amount * cur.price;

    return acc + boxPrice + itemsPrice;
  }, 0);  
}

  const getText = dayIndex => {
    switch (dayIndex) {
      case 0:
        return 'v neděli';
      case 1:
        return 'v pondělí';
      case 2:
        return 'v úterý';
      case 3:
        return 've středu';
      case 4:
        return 've čtvrtek';
      case 5:
        return 'v pátek';
      case 6: 
        return 'v sobotu';
      default:
        throw new Error('Invalid dayIndex');
    }
  }

  const getOpeningDayIndex = (openingHours, dayIndex) => {
    if (openingHours[dayIndex] === null) {
      return getOpeningDayIndex(openingHours, dayIndex + 1 % 7);
    }

    return dayIndex % 7;
  }

const prefix = s => `0${s}`.slice(-2);

export function getOpening (restaurant, date = new Date()) {
  // Replace days when restaurant is closed with null
  const openingHours = restaurant.openingHours.map(d => d.from === d.to ? null : d);

  const current = {
    day: date.getDay(),
    time: `${prefix(date.getHours())}:${prefix(date.getMinutes())}`,
  };

  const currentOpening = openingHours[current.day];

  const nextOpeningIndex = getOpeningDayIndex(openingHours, current.day + 1);

  // If restaurant is closed every day
  if (openingHours.every(o => o === null) || !restaurant.active) {
    return `Z této restaurace si nyní nelze nic objednat.`;
  }

  // Restaurant is open today, but isn't opened yet
  if (currentOpening !== null && current.time < currentOpening.from) {
    return `Restaurace otevírá dnes v ${currentOpening.from}`;
  }

  // Restaurant will be open tomorrow
  if (nextOpeningIndex - current.day === 1) {
    return `Restaurace otevírá zítra v ${openingHours[nextOpeningIndex].from}`;
  }

  // Restaurant opens some day
  return `Restaurace otevírá ${getText(nextOpeningIndex)} v ${openingHours[nextOpeningIndex].from}`;
}

export const getPaymentMethodLabel = paymentMethod => {
  return {
    cash: 'Hotově',
    card: 'Kreditní kartou',
  }[paymentMethod];
};