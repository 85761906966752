import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

import {
  displaySnackbar as displaySnackbarAction,
  addMealToOrder,
  removeMealFromOrder
} from '../../../../../redux/actions/app';

import s from './style.module.scss';

import Base from '../Base';

// TODO: Refactor duplicit order.find
const Meal = ({
  order,
  addMealToOrder,
  removeMealFromOrder,
  meal,
  isDrink,
  displaySnackbar,
  categoryDisabled,
}) => (
  <Base title={meal.name} {...meal}>
    <IconButton
      classes={{
        disabled: s.disabledMealAction,
        root: s.mealAction
      }}
      onClick={() => removeMealFromOrder(meal)}
      disabled={order.find(item => item.name === meal.name) ? order.find(item => item.name === meal.name).amount === 0 : true}
    ><Icon className={s.mealActionIcon}>remove</Icon></IconButton>
    <span className={s.mealAmount}>{order.find(item => item.name === meal.name) ? order.find(item => item.name === meal.name).amount : 0}</span>
    <div onClick={() => {
      if (meal.disabled || categoryDisabled) {
        displaySnackbar('Tuto položku nyní nelze objednat!');
      }
    }}>
      <IconButton
        disabled={Boolean(meal.disabled) || categoryDisabled}
        classes={{
          root: s.mealAction,
          disabled: s.disabledMealAction,
        }}
        onClick={() => {
          addMealToOrder({
            isDrink,
            ...meal
          })
        }}
      ><Icon className={s.mealActionIcon}>add</Icon></IconButton>
    </div>
  </Base>
);

Meal.propTypes = {
  meal: PropTypes.object.isRequired,
  order: PropTypes.array.isRequired,
  addMealToOrder: PropTypes.func.isRequired,
  removeMealFromOrder: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { app } = state;

  return {
    order: app.order,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addMealToOrder(meal) {
      return dispatch(addMealToOrder(meal));
    },
    removeMealFromOrder(meal) {
      return dispatch(removeMealFromOrder(meal));
    },
    displaySnackbar(message) {
      return dispatch(displaySnackbarAction(message));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Meal);
