import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { parseString } from '../../../../../functions/utils';

import { MealItem } from '../../../../common/ListItem';

import s from './style.module.scss';

const Meals = ({ categories }) => (
<div className={s.mealsContainer}>
  {categories.map(category => (
    <div key={category.name} className={s.mealSubcontainer}>
      <p className={s.categoryName} id={parseString(category.name)}>{category.name}</p>
      <ul className={s.mealsList}>
        {category.meals.map(meal => (
          <MealItem
            meal={meal}
            isDrink={!!meal.isDrink}
            key={meal.name}
            categoryDisabled={Boolean(category.disabled)}
          />
        ))}
      </ul>
    </div>
  ))}
</div>
);

Meals.propTypes = {
  categories: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Meals);
