import React from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';

import Homepage from './components/pages/Homepage';
import Restaurant from './components/pages/Restaurant';
import Recap from './components/pages/Recap';
import Confirmation from './components/pages/Confirmation';
import AdminLogin from './components/pages/Admin/Login';
import AdminDashboard from './components/pages/Admin/Dashboard';
import AdminRestaurantList from './components/pages/Admin/RestaurantList';
import AdminRestaurantDetail from './components/pages/Admin/RestaurantDetail';
import AdminOrdersList from './components/pages/Admin/OrdersList';
import AdminDrinks from './components/pages/Admin/Drinks';
import AdminModal from './components/pages/Admin/Modal';

import ScrollRestoration from './components/common/ScrollRestoration';

import history from './history';
import { paths } from './config';

export default () => (
  <Router history={history}>
    <ScrollRestoration>
      <Switch>
        <Route exact path={paths.homepage} component={Homepage}/>
        <Route exact path={paths.restaurant} component={Restaurant}/>
        <Route exact path={paths.recap} component={Recap}/>
        <Route exact path={paths.confirmation} component={Confirmation}/>
        <Route exact path={paths.adminLogin} component={AdminLogin}/>
        <Route exact path={paths.adminDashboard} component={AdminDashboard}/>
        <Route exact path={paths.adminRestaurant} component={AdminRestaurantList}/>
        <Route exact path={paths.adminRestaurantDetail} component={AdminRestaurantDetail}/>
        <Route exact path={paths.adminNewRestaurant} component={AdminRestaurantDetail} />
        <Route exact path={paths.adminOrdersList} component={AdminOrdersList} />
        <Route exact path={paths.adminDrinks} component={AdminDrinks} />
        <Route path={paths.adminModal} component={AdminModal} />
        <Route path={paths.adminDefault} component={() => <Redirect to={paths.adminLogin} />} />
        <Redirect to={paths.homepage} />
      </Switch>
    </ScrollRestoration>
  </Router>
);
