export const env = process.env.NODE_ENV;

export const environments = {
  prod: 'production',
  dev: 'development',
  test: 'test',
};

export const paths = {
  homepage                :         '/',
  restaurant              :         '/restaurace/:id',
  recap                   :         '/shrnuti-objednavky',
  confirmation            :         '/potvrzeni-objednavky',
  adminDefault            :         '/admin',
  adminLogin              :         '/admin/login',
  adminDashboard          :         '/admin/dashboard',
  adminRestaurant         :         '/admin/restaurants',
  adminRestaurantDetail   :         '/admin/restaurant/:restaurantId',
  adminNewRestaurant      :         '/admin/restaurant/add',
  adminOrdersList         :         '/admin/ordersList',
  adminDrinks             :         '/admin/drinks',
  adminModal              :         '/admin/modal',
};

export default {
  paths,
};
