import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getClass } from '../../../functions/utils';
import { getHomepageText } from '../../../functions/api';

import s from './style.module.scss';

class CourierContact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deliveryText: '',
      additionalText: '',
    }
  }

  async componentWillMount() {
    const homepageTexts = await getHomepageText();

    this.setState({
      ...homepageTexts,
    });
  }

  render() {
    const { withShadow } = this.props;
    const { deliveryText } = this.state;

    return (
      <p className={`${s.courierContact} ${getClass(withShadow, s.courierContactShadow)}`}>
        <span>Číslo na kurýra:</span>
        <a href={`tel:${deliveryText}`} className={s.courierContactLink}>{deliveryText}</a>
      </p>
    );
  }
}

CourierContact.propTypes = {
  withShadow: PropTypes.bool,
};

export default CourierContact;

