import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import { setActiveRestaurant } from "../../../redux/actions/app";
import { getCurrentDayIndex, isRestaurantOpen } from '../../../functions/utils';

import Sidebar from './components/Sidebar';
import Meals from './components/Meals';
import Message from "../../common/Message/index";
import Layout from '../../layout/Main';

import { paths } from '../../../config';
import history from '../../../history';
import { getDrinksObject } from '../../../api/drinks';

import s from './style.module.scss';

class Restaurant extends Component {
  static propTypes = {
    activeRestaurant: PropTypes.object.isRequired,
    setActiveRestaurant: PropTypes.func.isRequired,
    restaurants: PropTypes.array.isRequired,
    match: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      canAccess: false,
      drinksCategory: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    const {
      activeRestaurant,
      restaurants,
      match,
      setActiveRestaurant,
    } = nextProps;

    const currentRestaurant = restaurants.find(restaurant => restaurant.url === match.params.id);

    if (!currentRestaurant) {
      history.push(paths.homepage);
      return;
    }

    if (currentRestaurant.externalLink) {
      window.location.href = currentRestaurant.externalLink

      return
    }

    if (isRestaurantOpen(currentRestaurant.openingHours) && currentRestaurant.active) this.setState({ canAccess: true });
    if (!activeRestaurant.name) setActiveRestaurant(currentRestaurant);
  }

  componentWillMount() {
    const { activeRestaurant } = this.props;

    if (activeRestaurant.isOpen && activeRestaurant.active) this.setState({ canAccess: true });
  }

  async componentDidMount () {
    const drinks = await getDrinksObject();

    if (!drinks.disabled) {
      this.setState({
        drinksCategory: drinks,
      });
    }
  }

  render() {
    const { canAccess, drinksCategory } = this.state;
    const {
      activeRestaurant,
    } = this.props;

    // Add drinks to restaurant's categories
    const allCategories = [drinksCategory].concat(activeRestaurant.categories)

    return (
      <div>
        {activeRestaurant.name && drinksCategory ?
          <Layout image>
            {canAccess ?
              <div className={s.contentContainer}>
                <Sidebar
                  categories={allCategories}
                  openingHours={activeRestaurant.openingHours[getCurrentDayIndex()]}
                />
                <Meals
                  categories={allCategories}
                />
              </div>
              :
              <Message
                icon="sentiment_dissatisfied"
                message="Restaurace je nyní bohužel zavřená"
              />
            }
          </Layout>
          :
          <div className={s.loadingContainer}>
            <CircularProgress size={200} style={{ color: '#E00000' }}/>
          </div>
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { app, data } = state;

  return {
    activeRestaurant: app.activeRestaurant,
    restaurants: data,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setActiveRestaurant(restaurant) {
      dispatch(setActiveRestaurant(restaurant));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Restaurant);
