// Data reducer
export const SET_RESTAURANTS = 'SET_RESTAURANTS';

// App reducer
export const DISPLAY_MODAL = 'DISPLAY_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const DISPLAY_SNACKBAR = 'DISPLAY_SNACKBAR';
export const HIDE_SNACKBAR = 'HIDE_SNACKBAR';
export const SET_ACTIVE_RESTAURANT = 'SET_ACTIVE_RESTAURANT';
export const ADD_MEAL_TO_ORDER = 'ADD_MEAL_TO_ORDER';
export const REMOVE_MEAL_FROM_ORDER = 'REMOVE_MEAL_FROM_ORDER';
export const RESET_ORDER = 'RESET_ORDER';
export const SET_ORDER = 'SET_ORDER';
export const FINISH_ORDER = 'FINISH_ORDER';