import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import { Link } from 'react-router-dom';
import jump from 'jump.js';

import { parseString } from '../../../../../functions/utils';
import { paths } from '../../../../../config';
import Button from '../../../../common/Button';

import s from './style.module.scss';

/**
 * Sidebar component
 * TODO: Add current open time logic
 */
class Sidebar extends Component {
  static propTypes = {
    categories: PropTypes.array.isRequired,
    openingHours: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      sidebarFixed: 0,
    };

    this.scrollEvent = this.scrollEvent.bind(this);
  }

  scrollEvent() {
    if (window.screen.width > 600) {
      this.setState({ sidebarFixed: window.scrollY > 385 });
    } else {
      this.setState({ sidebarFixed: false });
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollEvent);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollEvent);
  }

  render() {
    const {
      sidebarFixed,
    } = this.state;

    const {
      categories,
      openingHours,
    } = this.props;

    return (
      <div className={s.sidebarContainer}>
        <div className={`${s.sidebar} ${sidebarFixed ? s.sidebarFixed : ''}`}>
          <div className={s.sidebarActions}>
            <Link to={paths.homepage}>
              <Button classes={{ root: s.sidebarButton }}>Změnit Restauraci</Button>
            </Link>
            <Link to={paths.recap}>
              <Button classes={{ root: `${s.sidebarButton} ${s.sidebarButtonMargin}` }}>Zobrazit košík</Button>
            </Link>
            <section className={s.sidebarOpening}>
              <span className={s.sidebarOpeningTitle}>Nyní otevřeno</span>
              <div className={s.sidebarOpeningTime}>
                <Icon classes={{ root: s.sidebarOpeningTimeIcon }}>access_time</Icon>
                <span>{openingHours.from} - {openingHours.to}</span>
              </div>
            </section>
          </div>
          <ul className={s.sidebarCategoriesList}>
            {categories
              .map(category => (
                <span
                  onClick={() => jump(`#${parseString(category.name)}`)}
                  key={category.name}
                  className={s.sidebarCategoryLink}
                >
                  <li className={s.sidebarCategoriesItem}>{category.name}</li>
                </span>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default Sidebar;
