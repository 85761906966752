import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { paths } from '../../../config';
import logo from '../../../img/logo.png';

import ig_icon from './img/instagram_icon.svg';
import fb_icon from './img/facebook_icon.svg';

import s from './style.module.scss';

/**
 * Logo component
 *
 * Displays a logo image and redirects to homepage on click
 */
const Logo = ({ to = paths.homepage, displaySocialIcons = false, className = ''}) => (
<div className={`${s.container} ${className}`}>
  {displaySocialIcons &&
    <a href="https://www.instagram.com/vybersijidlo/" className={s.ig_icon}>
      <img src={ig_icon} className={s.icon} alt="Vybersijidlo Instagram icon" />
    </a>
  }
  {displaySocialIcons &&
    <a href="https://www.facebook.com/Vybersijidlocz-2005433196411060/" className={s.fb_icon}>
      <img src={fb_icon} className={s.icon} alt="Vybersijidlo Facebook icon" />
    </a>
  }
  <Link to={to} className={s.logoLink}>
    <img src={logo} alt="Logo" className={s.logoImage} />
    <span className={s.logoText}>VyberSiJidlo.cz</span>
  </Link>
</div>
);

Logo.propTypes = {
  to: PropTypes.string,
  displaySocialIcons: PropTypes.bool,
};

export default Logo;
