import React from 'react';
import PropTypes from 'prop-types';
import {
  SortableContainer,
} from 'react-sortable-hoc';

import MealItem from '../MealItem';

import s from '../../style.module.scss';

const MealList = ({ items, categoryIndex, changeMealProperty, ...props }) => (
  <ul className={s.mealList}>
    {items.map((meal, index) => (
      <MealItem
        meal={meal}
        key={meal.key}
        index={index}
        categoryIndex={categoryIndex}
        changeMealProperty={changeMealProperty}
        {...props}
      />
    ))}
  </ul>
);

MealList.propTypes = {
  items: PropTypes.array.isRequired,
  categoryIndex: PropTypes.number.isRequired,
  changeMealProperty: PropTypes.func.isRequired,
};

export default SortableContainer(MealList);