import React from 'react';
import PropTypes from 'prop-types';
import {
  SortableElement,
} from 'react-sortable-hoc';
import Icon from '@material-ui/core/Icon';
import Switch from '@material-ui/core/Switch';

import DragHandle from '../DragHandle';

import s from '../../style.module.scss';

const MealItem = ({ meal, categoryIndex, changeMealProperty, updateMealProperty, ...props }) => (
  <li className={s.mealItem}>
    <DragHandle />
    <Icon
      className={s.deleteMealIcon}
      onClick={() => props.removeMeal(categoryIndex, meal.index)}
    >delete_forever</Icon>
    <input
      type="text"
      value={meal.name}
      onChange={changeMealProperty('name', categoryIndex, meal.index)}
      className={s.mealInput}
      placeholder="Název pokrmu"
    />
    <input
      type="text"
      value={meal.description}
      onChange={changeMealProperty('description', categoryIndex, meal.index)}
      className={s.mealInput}
      placeholder="Popis pokrmu"
    />
    <input
      type="text"
      value={meal.buyPrice || ''}
      onChange={changeMealProperty('buyPrice', categoryIndex, meal.index)}
      className={s.mealInput}
      placeholder="Cena pokrmu (nákup)"
    />
    <input
      type="text"
      value={meal.price}
      onChange={changeMealProperty('price', categoryIndex, meal.index)}
      className={s.mealInput}
      placeholder="Cena jídla (prodej)"
    />
    <Switch
      className={s.mealDisabledButton}
      checked={meal.disabled === undefined ? true : Boolean(!meal.disabled)}
      onChange={(e) => updateMealProperty('disabled', !e.target.checked, categoryIndex, meal.index)}
    />
  </li>
);

MealItem.propTypes = {
  meal: PropTypes.object.isRequired,
  categoryIndex: PropTypes.number.isRequired,
  changeMealProperty: PropTypes.func.isRequired,
};

export default SortableElement(MealItem);
