import firebase from 'firebase';
import { env, environments } from '../config';

export function log (message) {
  if (env === environments.dev) {
    console.error(message);
  }
}

export function getDatabaseRef (path) {
  return firebase.database().ref(`/${env}/${path}`);
}

export function getStorageRef (path) {
  return firebase.storage().ref(`${env}/${path}`);
}

export function parseData (data) {
  return data === null ? [] : Object.keys(data).map(key => ({ id: key, ...data[key] }))
}