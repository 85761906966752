import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import shortId from 'shortid'

import { paths } from '../../../../../config';
import history from '../../../../../history';
import { displaySnackbar, setActiveRestaurant, displayModal, resetOrder } from '../../../../../redux/actions/app';
import { getClass, getOpening } from '../../../../../functions/utils';

import Button from '../../../../common/Button';

import s from './style.module.scss';

function changeActiveRestaurant(setActiveRestaurant, restaurant) {
  setActiveRestaurant(restaurant);
  history.push(paths.restaurant.replace(':id', restaurant.url));
}

const RestaurantList = ({
  restaurants,
  displaySnackbar,
  setActiveRestaurant,
  displayModal,
  order,
  resetOrder,
  activeRestaurant,
}) => {
  const restaurantsLoaded = restaurants.length;

  const RestaurantItem = ({ restaurant }) => (
    <li className={s.restaurantsItem}>
      <Button
        xl
        classes={{ root: getClass((!restaurant.isOpen || !restaurant.active), s.disabledButton) }}
        onClick={() => {
          if (restaurant.isOpen && restaurant.active) {
            if(order.length && restaurant.name !== activeRestaurant.name) {
              displayModal({
                title: 'Upozornění',
                description: 'Pokračováním přijdete o současnou objednávku. Přejete si pokračovat ?',
                onContinue: () => {
                  changeActiveRestaurant(setActiveRestaurant, restaurant);
                  resetOrder();
                },
              });

              return;
            }

            if (restaurant.externalLink) {
              window.location.href = restaurant.externalLink;

              return
            }

            changeActiveRestaurant(setActiveRestaurant, restaurant);
          } else {
            displaySnackbar(getOpening(restaurant));
          }
        }}
      >{restaurant.name}</Button>
    </li>
  );

  return (
    <div className={s.restaurantsContainer}>
      {restaurantsLoaded ?
        <ul className={s.restaurantsList}>
          {restaurants
            .filter((restaurant) => restaurant.isOpen && restaurant.active)
            .map((restaurant) => (
              <RestaurantItem key={shortId.generate()} restaurant={restaurant} />
          ))}
          {restaurants
            .filter((restaurant) => !restaurant.isOpen || !restaurant.active)
            .map((restaurant) => (
              <RestaurantItem key={shortId.generate()} restaurant={restaurant} />
          ))}
        </ul>
        :
        <CircularProgress size={200} style={{ color: '#fff' }}/>
      }
    </div>
  );
};

RestaurantList.propTypes = {
  restaurants: PropTypes.array.isRequired,
  displaySnackbar: PropTypes.func.isRequired,
  setActiveRestaurant: PropTypes.func.isRequired,
  displayModal: PropTypes.func.isRequired,
  order: PropTypes.array.isRequired,
  activeRestaurant: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const { data, app } = state;

  return {
    restaurants: data,
    order: app.order,
    activeRestaurant: app.activeRestaurant,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    displaySnackbar(message) {
      dispatch(displaySnackbar(message));
    },
    displayModal(modal) {
      dispatch(displayModal(modal))
    },
    setActiveRestaurant(restaurant) {
      dispatch(setActiveRestaurant(restaurant));
    },
    resetOrder() {
      dispatch(resetOrder());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantList);

