import React, { Component } from "react";
import ReactMarkdown from "react-markdown";

import Logo from "../../common/Logo";
import RestaurantList from "./components/RestaurantList";

import { getHomepageText } from "../../../functions/api";

import s from "./style.module.scss";

class Homepage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deliveryText: "",
      additionalText: "",
    };
  }

  async componentWillMount() {
    const homepageTexts = await getHomepageText();

    this.setState({
      ...homepageTexts,
    });
  }

  render() {
    const { additionalText } = this.state;

    return (
      <div className={s.wrapper}>
        <span className={s.mobileBackground} />
        <div className={s.container}>
          <header className={s.header}>
            <Logo displaySocialIcons />
            <div className={s.headerTextContainer}>
              {additionalText && (
                <p className={s.headerText}>
                  <ReactMarkdown>{additionalText}</ReactMarkdown>
                </p>
              )}
            </div>
          </header>
          <RestaurantList />
        </div>
      </div>
    );
  }
}

export default Homepage;
