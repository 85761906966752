import React from 'react';
import Icon from '@material-ui/core/Icon';
import Collapse from '@material-ui/core/Collapse';
import {
  SortableElement,
} from 'react-sortable-hoc';

import MealList from '../MealList';
import DragHandle from '../DragHandle';
import Switch from '@material-ui/core/Switch';

import s from '../../style.module.scss';

const CategoryItem = ({
  category,
  expandCategory,
  activeCategory,
  changeMealProperty,
  changeCategoryName,
  changeNewCategoryValue,
  categoryInput,
  addCategory,
  onSortEnd,
  updateCategory,
  ...props,
}) => (
  <li key={category.key} className={s.categoryItem}>
    <div className={s.categoryName}>
      <Icon
        onClick={() => expandCategory(category.index)}
        className={s.categoryExpandIcon}
      >keyboard_arrow_down</Icon>
      <DragHandle />
      <Icon
        onClick={() => props.removeCategory(category.index)}
        className={s.deleteCategoryIcon}
      >delete_forever</Icon>
      <input
        type="text"
        value={category.name}
        className={s.categoryInput}
        onChange={changeCategoryName(category.index)}
      />
      <Switch
        checked={!Boolean(category.disabled)}
        onChange={event => {
          const value = !event.target.checked;
          updateCategory(category.index, 'disabled', value);
        }}
        color="secondary"
      />
    </div>
    <Collapse in={category.index === activeCategory} timeout="auto">
      <MealList
        changeMealProperty={changeMealProperty}
        categoryIndex={category.index}
        useDragHandle={true}
        items={category.meals.map((m, i) => ({ ...m, index: i }))}
        onSortEnd={onSortEnd(category)}
        {...props}
      />
      <div className={s.addMealContainer}>
        <input
          type="text"
          placeholder="Název jídla"
          className={s.addMealInput}
          value={props.mealInput.name}
          onChange={props.changeNewMealValue('name')}
        />
        <input
          type="text"
          placeholder="Popis jídla"
          className={s.addMealInput}
          value={props.mealInput.description}
          onChange={props.changeNewMealValue('description')}
        />
        <input
          type="number"
          placeholder="Cena jídla (nákup)"
          className={s.addMealInput}
          value={props.mealInput.buyPrice || ''}
          onChange={props.changeNewMealValue('buyPrice')}
        />
        <input
          type="number"
          placeholder="Cena jídla (prodej)"
          className={s.addMealInput}
          value={props.mealInput.price}
          onChange={props.changeNewMealValue('price')}
        />
        <span
          className={s.addMealButton}
          onClick={props.addMeal(category.index)}
        >Přidat jídlo</span>
      </div>
    </Collapse>
  </li>
);

export default SortableElement(CategoryItem);
