import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as firebase from 'firebase';
import Snackbar from '@material-ui/core/Snackbar';

import { setRestaurants } from '../redux/actions/data';
import { setOrder } from '../redux/actions/app';
import { getRestaurants, getModal } from '../functions/api';
import { displayModal } from '../redux/actions/app';

import Modal from './common/Modal';
import Router from './../router';

import s from './App.module.scss';

class App extends Component {
  static propTypes = {
    modal: PropTypes.object.isRequired,
    setRestaurants: PropTypes.func.isRequired,
    setOrder: PropTypes.func.isRequired,
  };

  /**
   * Initialize Firebase app
   */
  async componentWillMount() {
    const {
      setRestaurants,
      setOrder,
      displayModal,
    } = this.props;

    firebase.initializeApp({
      apiKey: "AIzaSyCUJCX2c0Jg-r8I6cBU0VkCz8wVuDnTzVQ",
      authDomain: "vybersijidlo-d1361.firebaseapp.com",
      databaseURL: "https://vybersijidlo-d1361.firebaseio.com",
      projectId: "vybersijidlo-d1361",
      storageBucket: "",
      messagingSenderId: "876699905539",
      appId: "1:876699905539:web:9f44457f3966fbe4"
    });

    setRestaurants(await getRestaurants());

    // Five minutes
    const time = 5 * 60 * 1000;
    const order = JSON.parse(localStorage.getItem('order')) || {};

    if (order.created + time > Date.now()) {
      setOrder(order.content);
    } else {
      localStorage.removeItem('order');
    }

    // Modal
    const modal = await getModal()
    const isAdmin = window.location.pathname.indexOf('/admin/') > -1

    if (modal.enabled && !isAdmin) {
      displayModal({
        title: modal.title,
        description: modal.content,
      })
    }
  }

  render() {
    const { modal, snackbar } = this.props;

    return (
      <div className={s.wrapper}>
        <Router />

        <Snackbar
          anchorOrigin={snackbar.position}
          open={snackbar.isOpen}
          message={snackbar.message}
        />

        <Modal
          open={modal.open}
          onContinue={modal.onContinue}
          onClose={modal.onClose}
          title={modal.title}
          description={modal.description}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { app } = state;

  return {
    modal: app.modal,
    snackbar: app.snackbar,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setRestaurants(restaurants) {
      return dispatch(setRestaurants(restaurants))
    },
    setOrder(order) {
      return dispatch(setOrder(order));
    },
    displayModal(modal) {
      dispatch(displayModal(modal));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);