import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

/**
 * Component responsible for scroll to top of the page on route change
 * @see https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/guides/scroll-restoration.md
 */
class ScrollRestoration extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

ScrollRestoration.propTypes = {
  children: PropTypes.any.isRequired,
  location: PropTypes.object,
};

export default withRouter(ScrollRestoration);
