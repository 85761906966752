import {
  DISPLAY_MODAL,
  HIDE_MODAL,
  DISPLAY_SNACKBAR,
  HIDE_SNACKBAR,
  SET_ACTIVE_RESTAURANT,
  ADD_MEAL_TO_ORDER,
  REMOVE_MEAL_FROM_ORDER,
  RESET_ORDER,
  SET_ORDER,
  FINISH_ORDER,
} from '../constants';

import store from '../store';

// TODO: Create as immutable variable
let timeout = null;

export function displayModal(modal = {}) {
  return {
    type: DISPLAY_MODAL,
    modal,
  }
}

export function hideModal() {
  return {
    type: HIDE_MODAL,
  }
}

export function displaySnackbar(message) {
  if (timeout) clearTimeout(timeout);

  timeout = setTimeout(() => store.dispatch(hideSnackbar()), 2000);

  return {
    type: DISPLAY_SNACKBAR,
    message,
  }
}

export function hideSnackbar() {
  return {
    type: HIDE_SNACKBAR,
  }
}

export function setActiveRestaurant(restaurant) {
  return {
    type: SET_ACTIVE_RESTAURANT,
    restaurant,
  }
}

export function addMealToOrder(meal) {
  return {
    type: ADD_MEAL_TO_ORDER,
    meal,
  }
}

export function removeMealFromOrder(meal) {
  return {
    type: REMOVE_MEAL_FROM_ORDER,
    meal,
  }
}

export function resetOrder() {
  return {
    type: RESET_ORDER,
  }
}

export function setOrder(order) {
  return {
    type: SET_ORDER,
    order,
  }
}

export function finishOrder() {
  return {
    type: FINISH_ORDER,
  }
}