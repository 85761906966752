import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

import history from '../../../../../history';
import { paths } from '../../../../../config';
import Basket from '../../../../common/Basket';

import s from './style.module.scss';

const MobileMenu = ({ order, activeRestaurant }) => (
  <ul className={s.mobileMenu}>
    <li
      className={s.mobileMenuItem}
      onClick={() => history.push(paths.homepage)}
    >
      <IconButton classes={{ root: s.mobileMenuIcon }}><Icon>home</Icon></IconButton>
    </li>
    <li
      className={s.mobileMenuItem}
      onClick={() => {
        const destination = activeRestaurant.name ? paths.restaurant.replace(':id', activeRestaurant.url) : paths.homepage;
        history.push(destination);
      }}
    >
      <IconButton classes={{ root: s.mobileMenuIcon }}><Icon>local_dining</Icon></IconButton>
    </li>
    <li
      className={s.mobileMenuItem}
      onClick={() => history.push(paths.recap)}
    >
      <Basket color="#757575"/>
    </li>
  </ul>
);

MobileMenu.propTypes = {
  order: PropTypes.array.isRequired,
  activeRestaurant: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const { app } = state;

  return {
    order: app.order,
    activeRestaurant: app.activeRestaurant,
  }
}

export default connect(mapStateToProps)(MobileMenu);

